.report-tabs {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
}

.report-col {
    min-width: 300px;
    height: 100%;
}

.report-dropdown {
  display: flex;
  padding: 4px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: var(--neutral-light-purple, #E6E7FF);
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
  cursor: pointer;
}
.report-slider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 35px;
}
.hours-cont {
  margin-top: 5%;
}

