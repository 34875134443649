button {
  cursor: pointer;
  transition: 0.1s;
}

.check-in-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.success-btn {
  background: var(--error-success-green, #9cf28f);
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  color: #5656b9;
}

.success-btn:hover {
  background: #e1ffdd;
  color: #35a400;
}

.all-btn {
  padding: 4px 16px;
  justify-content: center;
  border-radius: 16px;
  border: none;
  text-decoration: none;
}

.error-btn {
  background: var(--error-success-red, #ff6e6e);
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  color: #ffff;
}

.error-btn:hover:enabled {
  background: #fdd;
  color: #f23d3d;
}

.error-btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.form-cont .check-in-option {
  padding: 32px 96px;
  background: #9a9adc;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border-radius: 64px;
  color: #ffff;
  border: none;
}

.check-in-option:focus {
  border: 8px solid #9a9adc;
  padding: 24px 88px;
  background: #b9b9d7;
}

.change-pwd-btn {
  padding: 4px 16px;
  display: flex;
  border-radius: 16px;
  background: #cfbeff;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border: none;
  color: #ffff;
  order: 2;
}

.change-pwd-btn:disabled {
  background: #cfbeffa8;
}

.change-pwd-btn:hover:enabled {
  background: #a484ff;
}

.cancel-btn {
  padding: 4px 16px;
  /* display: flex; */
  border-radius: 16px;
  background: #b9b9d7;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border: none;
  color: #ffff;
  order: 1;
  height: fit-content;
  margin: auto 0;
}

.cancel-btn:hover {
  background: #5656b9;
}

.prompt-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 48px;
}

.faded-success-btn {
  background: #B9B9D7;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  opacity: 0.5;
  cursor:not-allowed;
}

.profile-pic-btn {  
  border-radius: 16px;
  background: var(--primary-lilac-lilac, #cfbeff);
  padding: 4px 16px;
  border: none;
  color: #5656b9;
  transition: background-color ease .2s, color ease .2s;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
}

.profile-pic-btn:hover {
  background-color: #a484ff;
  color: #fff;
}

.calendar-btn {
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--neutral-light-purple, #f4f4ff);
  border: none;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
}

.other-btn {
  padding: 4px 16px;
  gap: 8px;
  border: none;
  color: #fff;
  border-radius: 16px;
  background: var(--neutral-gray-gray, #5656b9);
  transition: background-color ease .2s, color ease .2s;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
}

.other-btn:hover {
  background-color: #9A9ADC;
}
.icon-col-btn {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: background-color .5s ease;
}
.icon-col-btn:hover {
  background-color: #FFF;
}