.truck-cont {
  width: 80%;
  padding: 50px;
  margin: auto;
}

.cont-404 {
  display: flex;
  width: 80%;
  gap: 24px;
  text-align: center;
  justify-content: center;
  padding: 50px;
}

.cont-404 h1 {
  font-size: 128px;
  font-weight: 900;
  color: #fff;
}

.error-cont {
  justify-content: center;
  padding: 50px 10px;
}

.error-cont h5 {
  color: #fff;
}

.error-cont a {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  margin-top: 10px;
  color: #5656b9;
  border-radius: 16px;
  background: var(--primary-lilac-lilac, #cfbeff);

  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
}

.rectangle-divider {
  width: 90%;
  height: 8px;
  border-radius: 16px;
  background: var(--neutral-light-purple, #f4f4ff);
  margin: auto;
}
