.yard-tabs {
    display: flex;
    list-style: none;
    gap: 48px;
    align-items: flex-start;
    padding: 0;
    padding-top: 12px;
}

.yard-tabs li {
    transition: 0.5s;
}

.yard-tabs .tab {
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    color: #9A9ADC;
}

.selected {
    color: #4A4A4A;
    border-bottom: 4px solid #CFBEFF;
    padding-bottom: 4px;
    transition: 0.2s;
}

.reservations-graphics {
    display: flex;
    gap: 48px;
    justify-content: flex-start;
    height: 500px;
    min-width: 600px;
    overflow: hidden;
    border-radius: 25px;
}

.reservations-cont {
    width: 100%;
    overflow: auto;
}

.map-graphic {
    width: 50%;
    background: #FFFF;
    border-radius: 16px;
    padding: 32px;
    height: 100%;
    overflow: hidden;
}

