.id-card-wrapper {
  position: relative;
  z-index: 10;
  background-color: #fff;
  padding: 32px;
  border-radius: 24px;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  overflow: hidden;
  height: 90%;
}

.id-card-wrapper iframe {
  height: 80%;
}
.front-back-id-cont {
  display: flex;
  gap: 8px;
}

.id-card-cont {
  width: 25vw;
  height: calc(25vw * 1.5943);
  margin: auto;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 1px 0px 20px 0px rgba(207, 190, 255, .3);
  background-repeat: no-repeat;
  background-size: cover;
}
.id-photo {
  margin-top: 60%;
  height: 30%;
  width: calc(30% * 1.59);  
  overflow: hidden;
}

.qr-code-agramont {
  margin-top: 50%;
  height: 30%;
}

.qr-code-jfg {
  margin-top: 30%;
  height: 30%;
}

.qr-code-jfg :only-child {
  max-width: 100%;
  max-height: 100%;
}
.qr-code-agramont :only-child {
  max-width: 100%;
  max-height: 100%;
}

.id-photo img {
  max-width: 100%;
  max-height: 100%;
}

.id-name {
  font-size: 2vw; 
  font-weight: 700;
  margin-top: 1%;
}

.id-job {
  font-size: 1.5vw; 
  color: red;
}
.ati-name {
  color: #0b2638;

}

.ati-job {
  margin-top: 23%;
  font-size: 24px;
  color: #FFFF;
}

.id-id {
  font-size: 1.8vw;
  font-weight: 700;
  margin-top: 3%;
}