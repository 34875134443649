.graphics-cont {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  gap: 24px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}


.justify-content-between {
  display: flex;
  justify-content: space-between;
}

.graphics-card {
  min-width: 425px;
  padding: 32px;
  background: #ffff;
  border-radius: 24px;
  box-shadow: 0px 0px 6.8px 0px rgba(145, 107, 255, 0.22);
}
.graphics-card-but-small {
  min-width: 280px;
  padding: 22px;
  background: #ffff;
  border-radius: 24px;
  box-shadow: 0px 0px 6.8px 0px rgba(145, 107, 255, 0.22);
}

.graphics-card h4 {
  text-align: start;
}

.recent-activity-cont {
  border-radius: 24px;
  background: var(--neutral-light-purple, #f4f4ff);
  padding: 16px;
}

.recent-activity {
  padding: 0 16px;
  border-radius: 16px;
  justify-content: center;
  background: #fff;
  height: 200px;
  overflow: auto;
}

.recent-activity svg {
  cursor: pointer;
}

.activity-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4ff;
  padding: 12px;
  gap: 5px;
  align-items: center;
  text-align: left;
}

.inventory-header {
  text-align: start;
  display: flex;
  gap: 16px;
  align-items: center;
}

.search-filters-cont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-right: 8px;
}

#filter-form {
  width: 100%;
}
.search-filters {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
}

.filter {
  display: inline;
  padding: 4px 16px;
  align-items: center;
  text-overflow: ellipsis;
  gap: 4px;
  width: 165px;
  border-radius: 16px;
  border: none;
  background: var(--secondary-medium-purple, #e6e7ff);
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border-right: 16px solid transparent;
  cursor: pointer;
}

.date-input-filter {
  padding: 4px 16px;
  align-items: center;
  text-overflow: ellipsis;
  width: 180px;
  border-radius: 16px;
  border: none;
  background: var(--secondary-medium-purple, #e6e7ff);
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  cursor: pointer;
}

.filter option {
  color: black;
}

.search-bar-cont {
  display: flex;
  border-radius: 16px;
  background: #ffff;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  padding: 4px 16px;
  max-height: 32px;
}

.search-bar-cont svg {
  color: #b9b9d7;
  width: 20px;
  height: 20px;
  margin: auto 0;
}

.search-bar-cont input {
  border: none;
  padding: 4px 32px 4px 16px;
  font-size: 12px;
}

.search-bar-cont input:focus {
  outline: none;
}

.reservation-item-cont {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}

@media (max-width: 500px) {
  .graphics-card {
    min-width: 100%;
    padding: 16px;
  }
  .activity-item {
    padding: 4px;
  }
  .graphics-card-but-small {
    width: 100%;
    min-width: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
