/* Slider styles --------------------- */

.slider-cont {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
}

.slider {
  position: relative;
  width: 500px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 6px;
}

.slider__track {
  background-color: #e6e7ff;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #cfbeff;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #393838;
  font-size: 18px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 500px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 3;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #cfbeff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #cfbeff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* Calendar styles --------------------- */

.calendar-cont {
  border-radius: 16px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 0px 7px 0px #cfbeff;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 5%;
}

.month-year {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  margin-bottom: 12px;
}

.week {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.week span {
  width: 30px;
  height: 30px;
  pointer-events: none;
}

.day-cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 300px;
}

.day,
.empty {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #f4f4ff00;
  transition: background-color ease 0.3s, color ease 0.3s;
}

.day:hover {
  color: #B9B9D7;
}

.day.day-clicked:hover {
  color: #212529;
}

.empty {
  text-align: center;
  width: 30px;
  height: 30px;
  color: #a7a7a7;
}

.day-clicked {
  background-color: #cfbeff;
}

.calendar-drop {
  background-color: var(--neutral-light-purple, #f4f4ff);
  overflow-x: auto;
}
.drop-menu-item {
  cursor: pointer;
  margin: 4px 0;
  color: #393838;
  transition: color .2s ease;
}
.drop-menu-item:hover {
  color: #B9B9D7;
}
.month-year svg {
  min-width: 17px;
  cursor: pointer;
}

.calendar-btn-cont {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

/* Switch stlyes ---------------------  */

.switch-cont {
  cursor: pointer;
  width: fit-content;
  margin: auto;
}

.switch-active {
  animation: switch-on .3s ease forwards;
}

.switch-deactive {
  animation: switch-off .3s ease forwards;
}

@keyframes switch-off {
  0% { transform: translateX(0px); }
  100% { transform: translateX(-22px); }
}

@keyframes switch-on {
  0% { transform: translateX(-22px); }
  100% { transform: translateX(0px); }
}


/* phone input styles ------------------------ */

.phone {
  max-height: 35px;
  padding: 4px 16px;
  border-radius: 16px;
  background: var(--neutral-light-purple, #f4f4ff);
  border: none;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  color: #5656b9;
  gap: 5px;
}
.phone input {
  color: #5656b9;
  width: 100%;
  background: var(--neutral-light-purple, #f4f4ff);
  border: none;
}
.phone input:focus{
  outline: none;
}
.phone svg {
  margin: auto;
}

.phone-icon-cont {
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.phone-drop-down {
  background: var(--neutral-light-purple, #f4f4ff);
  border-radius: 16px;
  position: absolute;
  margin-top: 35px;
  margin-left: -16px;
  padding: 4px 16px;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  z-index: 2;
}

.phone-drop-down span {
  cursor: pointer;
}