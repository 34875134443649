.form-cont,
.questions-cont,
.prompt-cont,
.view-report-modal,
.images-upload-cont {
  background: #ffff;
  text-align: start;
  z-index: 4;
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  max-height: 90vh;
  overflow: scroll;
}

.dropdowns {
  color: #5656b9;
}

.images-upload-cont {
  width: fit-content;

  overflow: auto;
}

.confirm-cont {
  position: relative;
  z-index: 5;
  overflow-x: scroll;
}

.questions-cont {
  max-height: 90vh;
  overflow: auto;
  max-width: 900px;
}

.auto-checkout {
  position: fixed;
}

.visit-ques-cont {
  position: relative;
  z-index: 6;
  overflow-y: scroll;
  max-height: fit-content;
}

.visitor-photo {
  display: flex;
  align-items: end;
  gap: 15px;
}
.visitor-photo img {
  max-width: 150px;
  border-radius: 12px;
}
.delete-icon svg {
  cursor: pointer;
}
.photo-preview {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.visitor-photo-cont {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: start;
}

.fixed-cont {
  top: 20%;
  position: fixed;
}

.prompt-cont .prompt-btns {
  padding-top: 20px;
}

.opensidebar {
  display: none;
}

.middle-cont {
  top: 30%;
}

.form-cont {
  padding: 32px;
}
.checkin-wrapper {
  margin: 64px;
  transition: margin-left 0.5s;
  margin-left: 64px;
}
.checkin-wrapper.shift {
  margin-left: 150px;
}

.form-cont h4 {
  font-size: 18px;
  color: #9a9adc;
  font-weight: 500;
}

.form-cont .check-in-form {
  width: 100%;
}

.form-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 48px;
  padding-bottom: 32px;
}

.report-item-row {
  display: flex;
  gap: 40px;
}

.report-item-row .report-item {
  width: 250px;
}

.report-item-description {
  width: 100%;
}

.report-item-row label {
  font-weight: 600
}
/* .input-cont-rows {
  display: flex;
} */

.input-cont-message {
  display: flex;
  flex-direction: column;
}

.form-group textarea {
  resize: none;
  height: 110px;
}

.form-group label {
  text-align: start;
  width: 67%;
  font-weight: 500;
  font-size: 16px;
}

#conditionSelect {
  margin-bottom: 20px;
}

.input-cont {
  display: flex;
  width: 100%;
  /* max-width: 300px; */
  gap: 12px;
}

.input-cont-search {
  display: block;
  width: 100%;
}

.search-results {

}

.input-cont select {
  padding: 4px 16px;
  border: none;
  width: 100%;
  border-radius: 16px;
  background: var(--neutral-light-purple, #f4f4ff);
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  color: #5656b9;
  border-right: 16px solid transparent;
  min-width: 135px;
}

.radio-options {
  display: flex;
  gap: 5px;
}

.input-cont select {
  outline: none;
  color: #5656b9;
}

.fieldsets {
  display: flex;
  gap: 18px;
  width: 100%;
  align-items: center;
}

.text-input {
  width: 100%;
  min-width: 135px;
  padding: 4px 16px;
  border-radius: 16px;
  background: var(--neutral-light-purple, #f4f4ff);
  border: none;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  color: #5656b9;
}

.date-input {
  background: var(--neutral-light-purple, #f4f4ff);
  color: #5656b9;
  border: none;
  border-radius: 16px;
  padding: 4px 16px;
  width: 100%;
}

.checkboxes-cont {
  width: 100%;
}

.check-cont {
  display: flex;
  gap: 12px;
}

.text-input:focus {
  outline: none;
  color: #5656b9;
}

.text-input-search {
  border-radius: 16px 16px 0 0;
  box-shadow: none;
}

.input-cont input::placeholder {
  color: var(--neutral-gray-gray, #5656b9);
}

.required:after {
  content: " *";
  color: red;
}

.form-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form-button button {
  padding: 4px 16px;
  border-radius: 16px;
  border: none;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  background: var(--primary-lilac-lilac, #cfbeff);
  color: #ffff;
  font-size: 12px;
  font-weight: 400;
}

.success-message {
  padding: 32px;
  border-radius: 24px;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  background: #ffff;
  text-align: center;
  z-index: 1000;
  max-width: 30%;
  position: relative;
}

.success-message-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--neutral-gray-gray, #5656b9);
  gap: 12px;
  padding: 8px;
  border-radius: 24px;
  background: var(--secondary-medium-purple, #e6e7ff);
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  margin: 24px 0;
}

.success-message p {
  text-align: left;
}

.success-message button {
  margin: 0 auto;
}

.input-cont fieldset {
  margin-right: 15px;
}

.search-input {
  display: inline;
  position: relative;
  width: 100%;
}

.search-results {
  display: block;
  background: white;
  text-align: start;
  color: #5656b9;
  width: 100%;
  background: var(--neutral-light-purple, #f4f4ff);
  border: none;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border-radius: 0px 0px 16px 16px;
  max-height: 200px;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.search-results li {
  list-style: none;
}

.search-results li span {
  padding-left: 8px;
}
.search-results li:hover {
  background: var(--neutral-light-purple, #d5d5ff);
}

.input-photo-cont {
  display: block;
  border-radius: 24px;
  background: var(--secondary-medium-purple, #e6e7ff);
  width: 200px;
  height: 144px;
  padding: 32px;
  cursor: pointer;
  margin-bottom: 24px;
}

.options-photo-cont {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 24px;
}
.file-input {
  display: none;
}

.images-cont {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: scroll;
  margin-bottom: 20px;
  max-height: 400px;
  /* overflow: scroll; */
}

.images-cont img {
  border-radius: 48px;
  padding: 24px;
  max-width: 100%;
}

.centered-cont {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

.input-photo-cont label {
  color: var(--neutral-gray-gray, #5656b9);
  text-align: center;
  display: block;

  /* Web/Paragraph */
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.image-column {
  position: relative;
  height: 200px;
}

.image-column img {
  max-width: 100%;
  max-height: 100%;
}

.image-column svg {
  position: absolute;
  cursor: pointer;
  top: 20%;
  right: 20%;
}

svg {
  height: auto;
}

.notes-cont {
  background-color: #f4f4ff;
  padding: 12px;
  border-radius: 24px;
}

.input-cont-margin-bottom {
  margin-bottom: 12px;
}

.input-cont-block {
  display: block;
  width: 100%;
}

@media (max-width: 1300px) {
  .form-cont {
    width: inherit;
  }
}

@media (max-width: 1000px) {
  .form-cont {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .form-group {
    flex-wrap: wrap;
    gap: 16px;
  }
  .checkin-wrapper {
    margin: 16px;
    transition: margin-left 0.5s;
    margin-left: 16px;
  }
  .flex-1 {
    flex-wrap: wrap;
  }
}
