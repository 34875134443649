.settings-cont {
  background: #ffff;
  width: 55vw;
  max-width: 100%;
  padding: 32px;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border-radius: 24px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 56px;
  align-self: stretch;
}

.settings-cont h2,
h4 {
  text-align: start;
  width: 100%;
}

.profile-pic-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.profile-pic {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  background-color: #cfbeff;
  border-radius: 50%;
  overflow: hidden;
}

.profile-pic svg{
  width: 150px;
  height: 150px;
}

.profile-pic-btns {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.info-cont {
  width: 100%;
  padding-bottom: 24px;
  display: flex;
  gap: 24px;
  margin: 0 auto;
  justify-content: flex-end;
}

.info-cont .text-input {
  padding: 4px 16px 4px 16px;
  width: 50%;
  border-radius: 16px;
  background: var(--neutral-light-purple, #f4f4ff);
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border: none;
}

.info-cont .label-cont {
  width: 100%;
}

.info-cont label {
  width: fit-content;
}

.info-cont .text-input:focus {
  outline: none;
}
.info-input-cont {
    width: 70%;
}

.personal-info-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.change-password-cont {
  position: relative;
  background: #ffff;
  text-align: start;
  /* width: 40%; */
  min-width: 50%;
  border-radius: 24px;
  padding: 32px;
  z-index: 4;
}

.change-password-cont h4 {
  padding-bottom: 24px;
}

.new-password {
  width: 100%;
}

.new-password label {
  display: block;
  width: 100%;
}

.new-password input {
  width: 100%;
}

.checkbox-cont {
  display: flex;
  gap: 10px;
  align-self: flex-start;
}
.checkbox-cont input {
  width: 12px;
  height: 12px;
  margin: auto 0;
  
}

.checkbox-cont p {
  margin: auto 0;
}

.full-cont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.appearance-setting {
  padding-bottom: 56px;
}

.themes-cont {
  display: flex;
  gap: 56px;
  width: 100%;
  justify-content: center;
}

.theme-option {
  border-radius: 10px;
}

.theme-option h5 {
  text-align: start;
}

.theme-radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-radio + img {
  cursor: pointer;
  max-width: 100%;
  border-radius: 5px;
}

[type="radio"]:checked + img {
  outline: 6px solid #cfbeff;
  margin-bottom: 5px;
  border-radius: 6px;
}

.save-cont {
  margin-top: 50px;
  width: 80%;
  justify-content: flex-end;
  display: flex;
}

.languages-options {
  display: flex;
  gap: 24px;
}

.chng-pass-cont {
  /* margin: auto; */
  align-self: last baseline;
  padding-bottom: 24px;
  width: 30%;
}

.theme-check-cont {
  position: absolute;
  transform: translateY(-60px) translateX(5px);
}

#flex-settings {
  display: flex;
  gap: 12px;
}


@media (max-width: 950px) {
  .settings-cont {
    width: 100%;
  }
  .chng-pass-cont {
    width: 100%;
    justify-content: flex-end;
  }
  #flex-settings {
    display: block;
  }
  .info-input-cont {
    width: 100%;
  }
  .cont-wrapper {
    width: 100%;
  }
  .info-cont {
    width: 100%;
    justify-content: start;
  }
}