/* Driver info  -------------------------------------------  */

.driver-info-cont {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: inline-flex;
  z-index: 5;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
}

.active-confirm {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.driver-photo {
  width: 128px;
  height: 128px;
  background-color: #f4f4ff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
}

.driver-photo img {
  max-width: 100%;
  max-height: 100%;
  scale: 107%;
}

.driver-photo svg {
  width: 128px;
  height: 128px;
}

.emergency-contact {
  margin: 32px 0;
}

.driver-info-cont :last-child {
  margin: 0;
}

.emergency-contact {
  text-align: left;
}

.emergency-contact p {
  color: var(--neutral-gray-gray, #5656b9);

  /* Web/Paragraph */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.close-cont {
  display: flex;
  padding: 1px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.close-cont svg {
  cursor: pointer;
}

.success-mssg {
  position: relative;
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  z-index: 4;
}

.success-mssg button {
  margin-top: 20px;
}

::placeholder {
  opacity: 0.5;
}

.e-contact-wrapper-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.e-contact-wrapper-bottom :last-child {
  align-self: end;
}

/* Animations -------------------------------------------  */
.slide-in-animation {
  animation: slideInRightToLeft 0.5s forwards;
}

.page-mask-animation {
  animation: fadeIn 0.5s forwards;
}

@keyframes slideInRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Edit driver ------------------------------------------- */
.edit-driver-cont {
  padding: 24px;
  border-radius: 24px;
  background: var(--neutral-white, #fff);
  text-align: start;
  max-height: 90vh;
  overflow-x: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 11;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
}

.edit-header {
  color: var(--neutral-gray-gray, #5656b9);

  /* Web/Subtitle */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.driver-photo-id {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
}

.driver-photo-id h2 {
  justify-self: start;
  width: 100%;
}

.emergancy-edit {
  margin: 56px 0;
}

.gap {
  margin-right: 35px;
}

.area {
  min-height: 90px;
}

@media (max-width: 1300px) {
  .edit-driver-cont {
    margin-left: 0;
  }
  .driver-info-cont {
    overflow-x: scroll;
  }
}

/* Add new driver forms ------------------------ */

.add-new-driver-cont {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  border-radius: 24px;
  margin-bottom: 24px;
  background: var(--neutral-white, #fff);

  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
}
.new-driver-header {
  margin-bottom: 24px;
}

/* Scanned ID ------------------------ */
.id-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scanned-info-cont {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
}

.scanned-name {
  color: var(--neutral-black-black, #393838);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  text-wrap: nowrap;
}

.active-badge {
  display: flex;
  padding: 0px 8px;
  align-items: center;
  border-radius: 4px;
  background: var(--error-success-light-green, #e1ffdd);
  color: #35a400;
  width: max-content;
  height: 30px;
}
.active-badge p {
  margin: 0;
}

.not-active-badge p {
  margin: 0;
}

.not-active-badge {
  display: flex;
  padding: 0px 8px;
  align-items: center;
  border-radius: 4px;
  color: var(--error-success-primary-red, #fb6060);
  background: var(--error-success-light-red, #fdd);
  width: max-content;
  height: 30px;
}

.company-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.company-info span {
  margin-left: 8px;
}

.company-info.jfg img {
  max-width: 188px;
  margin: 0;
  padding: 0;
}

.password-block {
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  padding: 24px;
  border-radius: 24px;
  margin: 50px 0;
}

.error-text {
  color: #fb6060;
}

@media (max-width: 500px) {
  .id-card {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-direction: column;
    gap: 15px;
  }
  .company-info div {
    text-align: start;
    width: 100%;
    font-size: 12px;
  }
}

@media (max-height: 500px) {
  .id-card {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    gap: 15px;
  }
  .company-info div {
    text-align: start;
    max-width: 100%;
    font-size: 12px;
    text-wrap: nowrap;
  }
}

@media (max-width: 500px) {
  .active-confirm {
    margin-left: 0px;
  }
}
