.table-cont {
  max-height: 60vh;
  min-height: 600px;
  overflow: scroll;
  border-radius: 24px;
}

.yard-table {
  width: 100%;
}

.yard-table thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.yard-table th:first-child {
  border-top-left-radius: 24px;
}

.yard-table th:last-child {
  border-top-right-radius: 24px;
}

.yard-table tr:last-child td:first-child {
  border-bottom-left-radius: 24px;
}

.yard-table tr:last-child td:last-child {
  border-bottom-right-radius: 24px;
}

.table-header {
  background: var(--secondary-medium-purple, #e6e7ff);
  padding: 24px;
}

.yard-table th {
  font-weight: 400;
  text-align: center;
  padding: 8px 30px;
  text-wrap: nowrap;
}

.data-rows td {
  padding: 1.2vw;
  text-align: center;
  text-wrap: nowrap;
  min-width: none;
}

.data-rows td:last-child svg {
  cursor: pointer;
}

.data-rows {
  border-bottom: 1px solid #e6e7ff;
  background: #ffff;
}

.row-hover {
  cursor: pointer;
  background: #ffff;
  transition: 0.5s background ease;
}

.row-hover:hover {
  background: #f4f4ff;
}

.reservation-table {
  width: 100%;
  overflow: auto;
}

.reserve-col {
  text-align: start;
}

.col-header {
  display: flex;
  background: none;
  border: none;
  align-items: center;
  gap: 4px;
  justify-content: center;
  margin: auto;
}

.order-filter {
  margin: auto 0;
}

.order-filter svg {
  display: block;
  width: 10px;
  height: 11px;
}

@media (max-width: 500px) {
  .table-cont {
    width: 95vw;
  }
  .yard-table thead {
    display: none; /* Hide table headers */
  }

  .yard-table tr {
    display: block;
    margin-bottom: 10px;
    border-radius: 16px;
    max-height: 65px;
    overflow: hidden;
    transition: max-height ease 0.5s;
  }

  .yard-table td {
    display: flex;
    justify-content: start;
    align-items: start;
    padding-left: 16px;
    /* border: 1px solid #ddd; */
    position: relative;
    flex-direction: column;
  }

  .yard-table td::before {
    content: attr(data-label);
    margin-right: 16px;
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
    color: #393838;
  }
  .row-hover:hover {
    background: #ffff;
  }
}
