.image-modal {
  border-radius: 24px;
  background: var(--neutral-white, #fff);
  justify-content: center;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  max-width: 100%;
  min-width: 600px;
  max-height: 90svh;
}

.preview-modal {
  border-radius: 24px;
  background: #FFFF;
  justify-content: center;
  box-shadow: 0px 0px 70x 0px rgba(207, 190, 255, 0.4);
  display: inline-flex;
  padding: 32px;
  max-width: 80%;
  min-width: 60%;
  min-height: 800px;
}
iframe {
  width: 800px;
  height: 800px
}

.edit-modal {
  border-radius: 24px;
  background: var(--neutral-white, #fff);
  justify-content: start;
  margin-left: 115px;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  max-width: 100%;
  max-height: 90svh;
  overflow-y: scroll;
}

.edit-modal p {
  text-align: left;
}

.option-modal {
  border-radius: 24px;
  background: var(--neutral-white, #fff);
  justify-content: center;
  align-items: center;
  margin-left: 115px;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
  display: inline-flex;
  padding: 32px;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  max-width: 100%;
  max-height: 90svh;
}

.no-img-found {
  margin: auto;
}

.image-modal p {
  text-align: left;
}

.photo-grid {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px;
  flex-wrap: wrap;
  max-height: 430px;
  overflow: scroll;
}

.placeholder2 {
  display: flex;
  max-width: 200px;
  max-height: 267px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 24px;
  background: #e6e7ff;
  transition: box-shadow 0.5s ease;
  cursor: pointer;
}

.placeholder2:hover {
  box-shadow: 0px 0px 6.8px 0px rgba(145, 107, 255, 0.22);
}

.placeholder2 img {
  max-width: 100%;
  max-height: 100%;
}

.close-btn {
  padding: 4px 16px;
  display: flex;
  border-radius: 16px;
  background: #cfbeff;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  border: none;
  color: #ffff;
  transition: background-color ease 0.2s;
}

.close-btn:hover {
  background-color: #a484ff;
}

.btn-cont {
  width: 100%;
  direction: rtl;
  margin-top: 2%;
}

.modal-cont2 {
  position: relative;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* bigger img modal */

.enlarged-modal {
  position: fixed;
  padding: 32px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 15px;
  border-radius: 24px;
  background: var(--neutral-white, #fff);
  max-height: 100svh;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
}

.img-cont {
  overflow: hidden;
}

.img-cont img {
  max-width: 100%;
  border-radius: 24px;
  background: var(--secondary-medium-purple, #e6e7ff);
}

.option-btn-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 24px;
}

.img-modal-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
  margin-left: 115px;
}

@media (max-width: 1300px) {
  .image-modal {
    max-height: 90vh;
    max-width: 100vw;
  }
  .btn-cont {
    margin-top: 0;
  }
  .placeholder2 {
    max-width: 200px;
    max-height: 267px;
  }
  .modal-cont2 {
    max-width: 82vw;
  }
}

@media (max-width: 500px) {
  .edit-modal {
    margin-left: 0;
  }
  .image-modal {
    max-height: 90vh;
    max-width: 80vw;
    min-width: 0;
  }

  .img-modal-loader {
    margin-left: 0;
  }
}
