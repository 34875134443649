.cont {
  height: 100vh;
  width: 100%;
  display: flex;
}

.left-cont {
  width: 40%;
  background: var(--secondary-medium-purple, #e6e7ff);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-img {
  width: 100%;
  padding: 0 64px;
}

.left-cont img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 24px;
}

.left-cont p {
  color: var(--primary-primary-blue, #557aff);
  text-align: center;

  /* Subtitle */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.right-cont {
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.login-cont {
  align-items: start;
  text-align: start;
}

.logo-cont {
  display: flex;
  gap: 16px;
}

.bottom-space {
  margin-bottom: 24px;
}

.right-cont svg {
  width: 37px;
  height: 32px;
}

.right-cont h4 {
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins";
  color: #557aff;
}

.right-cont h1 {
  font-size: 40px;
  font-weight: 800;
}

.right-cont p {
  color: var(--neutral-gray-gray, #b9b9d7);

  /* Subtitle */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.auth-group {
  margin-bottom: 16px;
  display: flex;
  background: var(--neutral-light-purple, #f4f4ff);
  padding: 4px 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
}

.right-cont input {
  width: 100%;
  padding: 4px 16px;
  background: var(--neutral-light-purple, #f4f4ff);
  border: none;
  font-size: 16px;
}

.right-cont input:focus {
  outline: none;
}

.right-cont input::placeholder {
  color: var(--neutral-gray-gray, #b9b9d7);
}

.link-cont a {
  color: #4a4a4a;
  font-size: 12px;
}

.right-cont button {
  display: flex;
  width: 100%;
  justify-content: center;
}

.auth-group svg {
  color: #b9b9d7;
  width: 20px;
  height: 20px;
  margin: auto 0;
}

.login-error {
  text-align: center;
  background: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #b9b9d7;
  padding: 24px 12px;
  border-radius: 48px;
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.22);
  animation-name: loginError;
  animation-duration: 0.5s;
}

.login-error p {
  margin: auto 0;
  color: #ff6e6e;
}
.login-img {
  border-radius: 5px;
  box-shadow: 5px 0px 15px 0px rgba(0, 0, 0, 0.22);
}

@keyframes loginError {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loading-cont {
  display: flex;
  order: 2;
  justify-content: center;
}

@media (max-width: 500px) {
  .left-cont {
    width: 100%;
    display: none;
  }
  .right-cont {
    width: 100%;
  }
  .cont {
    flex-direction: column;
  }
}
