.dropdown-list {
  position: absolute;
  z-index: 3;
  max-height: 300px;
  overflow-x: auto;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 16px;
  background-color: var(--secondary-medium-purple, #e6e7ff);
  margin-top: 15px;
  text-align: start;
  background-color: #F4F4FF;

  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
}

.dropdown-checkbox-cont {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

.dropdown-checkbox-cont input {
  cursor: pointer;
}

.fancy {
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--secondary-medium-purple, #e6e7ff);
  cursor: pointer;
  /* drop shadow */
  box-shadow: 0px 0px 7px 0px rgba(207, 190, 255, 0.4);
}

.fancy-carrot {
  margin-left: 8px;
  transform: rotate(180deg);
  transition: transform ease 0.3s;
}

.rotate {
  transform: rotate(0deg);
}

.fancy-list-item {
  cursor: pointer;
  width: 100%;
  padding: 3px 6px;
  border-radius: 5px;
  transition: color .2s ease;
}

.fancy-list-item:hover {
  color: #B9B9D7;
}

@media (max-width: 1300px) {
  .dropdown-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 3;
    max-height: 300px;
    overflow-x: scroll;
    padding: 16px 16px;
    gap: 50px;
    border-radius: 16px;
    background: var(--secondary-medium-purple, #e6e7ff);
    margin-top: 15px;
  }
  .dropdown-checkbox-cont input{
    transform: scale(200%);
    width: 50px;
  }
}
