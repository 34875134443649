.navbarCont {
  display: flex;
  position: fixed;
  width: calc(100% - 236px);
  height: 88px;
  padding: 0px 64px;
  justify-content: space-between;
  right: 0;
  z-index: 3;
  align-items: center;
  flex-shrink: 0;
  justify-self: end;
  background: #ffff;
  transition: padding-left 0.5s ease;
}

.navbarCont.shift {
  padding-left: 200px;
}

.profile-content {
  justify-content: flex-start;
  text-align: start;
}

.profile-content svg {
  margin: auto 0;
}

.profile-content a {
  text-decoration: none;
  display: flex;
  gap: 16px;
}

.sidebar,
.sidebar-active {
  position: fixed;
  display: flex;
  width: 236px;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 3;
  padding: 32px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  padding-bottom: 50px;
}

.sidebar-system {
  background: #295ffc;
}

.sidebar-agramont {
  background: #155173;
}

.sidebar-header,
.logo-a {
  justify-content: center;
  display: flex;
  gap: 16px;
  color: #ffff;
  max-height: 100vh;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

.sidebar-name {
  font-weight: 700;
  font-size: 24px;
  align-self: center;
  margin: auto 0;
}

.sidebar-header .logo {
  width: 38px;
  height: 32px;
}

.sidebar-header .opensidebar {
  display: none;
}

.side-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  list-style: none;
  padding-left: 0;
}

.menu-item {
  margin-top: 50px;
  cursor: pointer;
}

.menu-item.mobile {
  margin: 32px 0;
}

.menu-li {
  display: flex;
  gap: 16px;
}

.sub-menu {
  display: grid;
  position: relative;
  grid-template-rows: 0fr;
  list-style-type: none;
  padding : 0;
  margin: 0;
  transition: 0.3s ease-out; 
  > div {
    overflow: hidden;
  }
}

.sub-menu.expanded {
  grid-template-rows: 1fr;
}

.mobile-sub-menu {
  display: grid;
  position: relative;
  background: #FFFF;
  grid-template-rows: 0fr;
  margin-top: 12px;
  width: 200px;
  left: 90px;
  padding: 0;
  list-style-type: none;
  transition: 0.3s ease-out; 
  > div {
    overflow: hidden;
  }
}

.mobile-sub-menu.expanded {
  white-space: nowrap;
  padding: 15px 10px;
  border-radius: 10px;
  grid-template-rows: 1fr;
  > div {
    overflow: unset;
  }
}

.sub-menu-item {
  position: relative;
  padding-left: 20px;
  margin: 0;
  cursor: pointer;
  text-align: left;
  display: flex;
  gap: 16px;
}

.sub-menu-item::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #cfbeff;
}

.sub-menu-item.highlight::before {
  background: #FFFF;
  transition: 0.2s ease-in;
}

.mobile-sub-menu-item {
  position: relative;
  padding-left: 20px;
  margin: 0;
  cursor: pointer;
  text-align: left;
  display: flex;
  gap: 16px;
}

.mobile-sub-menu-item::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #cfbeff;
}

.mobile-sub-menu-item.highlight::before {
  background: #295FFC;
  transition: 0.2s ease-in;
}

.sidebar-system .inactive:hover {
  background: #2ebbee10;
  box-shadow: 0 0 2px 2px #2ebbee10;
  text-shadow: 0 0 2px #cfbeff60, 0 0 4px #cfbeff60, 0 0 8px #cfbeff60,
    0 0 16px #cfbeff60, 0 0 20px #cfbeff60, 0 0 25px #cfbeff60;
}

.sidebar-agramont .inactive:hover {
  background: #cfbeff10;
  box-shadow: 0 0 10px 5px #cfbeff10;
  text-shadow: 0 0 2px #2ebbee60, 0 0 4px #2ebbee60, 0 0 8px #2ebbee60,
    0 0 16px #2ebbee60, 0 0 20px #2ebbee60, 0 0 25px #2ebbee60;
}

.menu-category a,
.menu-category span {
  font-size: 16px;
  text-decoration: none;
  display: flex;
  gap: 16px;
}

.sub-menu a {
  text-decoration: none;
}
.menu-category svg {
  width: 20px;
  height: 20px;
}

.logout {
  position: relative;
  bottom: 0;
}

.active-span {
  color: white;
  text-decoration: none;
}

.active-span.mobile {
  color: #295FFC;
}

.inactive.mobile {
  color: #CFBEFF;
  text-decoration: none;
}

.s-inactive {
  color: #cfbeff;
  text-decoration: none;
}
.a-inactive {
  color: #2ebcee;
  text-decoration: none;
}

.build-number {
  color: #2ebbee3e;
}

@media (max-width: 1300px) {
  .sidebar {
    width: 85px;
  }

  .logo-a,
  span {
    transition: 0.5s;
  }

  .sidebar .logo-a {
    display: none;
  }

  .sidebar-active .span,
  .logo-a {
    display: flex;
  }

  .opensidebar {
    display: flex;
    transition: 0.5s;
  }

  .sidebar-header .opensidebar {
    display: flex;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .sidebar .logo {
    display: none;
  }

  .sidebar .sidebar-name {
    display: none;
  }

  .side-menu {
    padding-top: 50px;
  }

  .navbarCont {
    width: calc(100% - 85px);
  }

  .menu-item {
    margin-top: 40px;
  }

  .sidebar .single-route span {
    display: none;
  }

  .sidebar .menu-li {
    justify-content: center;
  }
}

@media (max-width: 1000px) {
  .sidebar,
  .sidebar-active {
    height: 100%;
  }
}

@media (max-width: 500px) {
  .navbarCont {
    justify-content: center;
    width: 100%;
    gap: 14px;
  }

  .sidebar-name {
    font-size: 30px;
  }

  .sidebar-header svg {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .navbarCont h2 {
    display: none;
  }

  .sidebar {
    display: none;
  }



  .sidebar-active.mobile {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: start;
    padding: 16px;
  }

  .sidebar-active.mobile.open {
    left: 0;
  }

  .sidebar-active.mobile.close {
    left: -100%;
  }

  .sidebar-header {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 400px) {
  .profile-id {
    display: none;
  }
  
  .navbarCont {
    justify-content: space-between;
    width: 100%;
    gap: 14px;
  }

}
